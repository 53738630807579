<template>
	<v-container class="photos">
		<template v-for="(item, index) in summaryData">
			<div v-for="img in item.images" class="block" :key="item.id+img.name">
				<div class="header"
					:style="[
						{
							'background-color': current_prop(item).color
							,'color': current_prop(item).text_color
						},	
					]"
					@click.stop="onClickHeader(item)"
				><span>{{item.id}} {{item.name}}</span><span><img src="~@/assets/icon_arrow.svg" class="arrow" /></span></div>
				<img :src=img.img @click=onClickPhoto(img.img) />
				<img src="~@/assets/icon_enlarge.svg" class="icon" @click=onClickPhoto(img.img) />
				<div class="name">{{img.name}}&nbsp;&nbsp;{{waterLevelFormat(current_waterlevels[index])}}</div>
				<div class="time">{{last_update(item.lastUpdate)}}</div>
			</div>
        </template>
		<div class="modal" v-if="showModal" @click="closeModal()" >
			<div class="box"  >
				<img :src=modal_src />
			</div>
		</div>
	</v-container>
</template>

<script>

import { mapState } from "vuex";

export default {
	components: {
	},
	computed: {
		...mapState("places", ["summaryData"])
	},
	created() {
		// for (const key of Object.keys(this.$store.state.places.summaryData)) {
		for (let i = 0; i < this.summaryData.length; i++) {
			let summary = this.summaryData[i];
			if (Object.keys(summary.waterlevel).length != 0) {
				this.current_waterlevels[i] = summary.waterlevel.level[summary.waterlevel.level.length-1];
			} else {
				this.current_waterlevels[i] = 0;
			}
		}
	}
	,
	data() {
		return {
			showModal: false
			,modal_src : ''
			,current_waterlevels: {}
		};
	},
	methods:{
        last_update : function(d){ 
          const t = new Date(d)
          return '更新 : '+ this.dateFormat(t,'yyyy.MM.dd hh:mm')
        },
		current_prop: function(place){
			return this.$store.getters.getAlertColor(place);
		}
		,onClickPhoto(s){
          this.showModal = true;
          this.modal_src = s;
        }
        ,closeModal(){
          this.showModal = false;
        }
		,onClickHeader(place) {
			let index = this.$store.getters.getPlaceIndexById(place.id);
			this.$router
				.push({ name: "Place", params: { id: index, item: place } })
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss">

.container.photos { 
	display: flex; flex-wrap: wrap;  justify-content: flex-start; padding: 1vw !important;
	.block { 
		width: 32.6%; margin: 0 1% 1% 0; background-color: white; border-radius: 10px; overflow:hidden; position: relative;
		.header { cursor:pointer;  }
		&:nth-child(3n) { margin-right: 0;  }
		img { 
			width: 100%; height: 250px;  object-fit: cover; cursor:pointer; 
			&.arrow { height: 20px; display: block; }
			&.icon { 
				width: 30px; height: 30px; position: absolute; top: 50px; right: 10px; border-radius: 0; background-color: rgba(255,255,255,.7);
				overflow: hidden; 
			}
		}
		.name { padding: .2em .4em; font-size: 18px; font-weight: bold; color: #666;  }
		.time { padding: 0 .4em .2em; color: #666; }
		@media screen and (max-width:720px){
			width: 100%; margin-right: 0; 
		}
	}
}
</style>